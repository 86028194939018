<template>
  <div class="catering mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Catering</h1>
        </v-col>
        <v-col class="text-sm-right"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "catering",
  methods: {}
};
</script>

<style lang="scss"></style>
